<script>
import request from '@/found/utils/request';
import TablePage from '../table_page';
// import Form from '../form'; // 取消客户验收单匹配表单
// import RemarkForm from '../form/remark_form.vue'; // 添加备注表单
// import FormDetail from '../form/form_detail.vue'; // 详情表单
// import FormGetSAP from '../form/form_get_SAP.vue'; // 抽取SAP数据

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    // Form,
    // RemarkForm,
    // FormDetail,
    // FormGetSAP,
  },
  props: {
    treeCode: String,
  },
  data() {
    return {
      params: {
        auditTemplateId: '',
      },
      paginationSize: [50, 150, 200, 500, 1000],
      tablePage: {
        currentPage: 1,
        pageSize: 50,
        totalResult: 0,
      },
    };
  },
  watch: {
    treeCode(val) {
      this.params.auditTemplateId = val;
      this.getList();
    },
  },
  async created() {
    await this.getConfigList('audit_summary_list');
    // this.getSummaryData();
  },
  methods: {
    modalClick({ val, row }) {
      this.formName = '';
      if (val.code === 'add_remark') {
        // 添加备注
        this.formConfig = {
          type: val.code,
          id: row.id,
          row,
        };
        this.formName = 'RemarkForm';
        this.modalConfig.title = '添加备注';
        this.modalConfig.width = '50%';
        this.openModal();
      } else if (val.code === 'cancel_matching_acceptance' || val.code === 'manual_matching_acceptance') {
        // 取消客户验收单匹配、人工匹配验收单
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.formName = 'Form';
        this.modalConfig.title = (val.code === 'cancel_matching_acceptance') ? '手动取消验收单' : '手动匹配验收单';
        this.openFull();
      } else if (val.code === 'view') {
        // 详情
        this.formName = 'FormDetail';
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.modalConfig.title = '详情';
        this.openFull();
      } else if (val.code === 'extract_SAP_data') {
        // 抽取SAP数据
        this.formName = 'FormGetSAP';
        this.formConfig = {
          type: val.code,
          id: row.id,
          auditTemplateId: this.params.auditTemplateId,
        };
        this.modalConfig.title = '抽取SAP数据';
        this.modalConfig.width = '50%';
        this.openModal();
      }
    },
  },
};
</script>
